/* @format */

import {
  TYPE,
  ALGOLIAFACETS,
  generateDynamicFacetName,
  SOLDES_PARAM_TRANSLATE,
} from '@/config/algolia/config'

export default function generateUrl(state, path, currentInterStore) {
  const splittedUrl = path.split('?')
  let baseUrl = splittedUrl[0]
  const query = splittedUrl[1] ?? null

  const { filters, ranges, page, sort, type } = state
  let newSort = state.orders.find((item) => item.value === sort)
  if (newSort) {
    newSort = newSort.sort
  }
  const ret = {
    artists:
      filters &&
      filters[ALGOLIAFACETS.global.artists.name] &&
      filters[ALGOLIAFACETS.global.artists.name].join('~').replace(/ /g, '%20'),
    brands:
      filters &&
      filters[ALGOLIAFACETS.global.brand.name] &&
      filters[ALGOLIAFACETS.global.brand.name].join('~').replace(/ /g, '%20'),
    colors:
      filters &&
      filters[generateDynamicFacetName(currentInterStore.langCode, 'COLORS')] &&
      filters[generateDynamicFacetName(currentInterStore.langCode, 'COLORS')]
        .join('--')
        .replace(/#/g, '%23')
        .replace(/ /g, '%20'),
    materials:
      filters &&
      filters[
        generateDynamicFacetName(currentInterStore.langCode, 'MATERIALS')
      ] &&
      filters[generateDynamicFacetName(currentInterStore.langCode, 'MATERIALS')]
        .join('--')
        .replace(/ /g, '%20'),
    prices:
      ranges &&
      ranges[generateDynamicFacetName(currentInterStore.langCode, 'PRICE')] &&
      ranges[generateDynamicFacetName(currentInterStore.langCode, 'PRICE')]
        .min +
        '~' +
        ranges[generateDynamicFacetName(currentInterStore.langCode, 'PRICE')]
          .max,
    promotions:
      ranges &&
      ranges[ALGOLIAFACETS.global.promotion] &&
      ranges[ALGOLIAFACETS.global.promotion].min +
        '~' +
        ranges[ALGOLIAFACETS.global.promotion].max,
    sizes:
      filters &&
      filters[ALGOLIAFACETS.global.size.ranked.nameWithType] &&
      filters[ALGOLIAFACETS.global.size.ranked.nameWithType]
        .join('--')
        .replace(/ /g, '%20'),
    ranges:
      filters &&
      filters[ALGOLIAFACETS.global.ranges.name] &&
      filters[ALGOLIAFACETS.global.ranges.name].join('~').replace(/ /g, '%20'),
    'eco-label':
      filters &&
      filters[
        generateDynamicFacetName(currentInterStore.langCode, 'ECO_FRIENDLY')
      ],
    sort: newSort,
    page: page > 0 ? page + 1 : null,
  }

  if (query) {
    const queryParams = new URLSearchParams(query)
    const entries = Array.from(queryParams.entries())
    for (const [k, v] of entries) {
      if (!(k in ret)) {
        ret[k] = v
      }
    }
  }
  let gender = ''
  if (
    filters &&
    filters[generateDynamicFacetName(currentInterStore.langCode, 'GENDERS')] &&
    filters[generateDynamicFacetName(currentInterStore.langCode, 'GENDERS')]
      .length > 0
  ) {
    gender = filters[
      generateDynamicFacetName(currentInterStore.langCode, 'GENDERS')
    ]
      .join('.')
      .replace(/ /g, '%20')
      .toLowerCase()
  }

  let gamme = ''
  if (
    filters &&
    filters[ALGOLIAFACETS.global.ranges.id] &&
    filters[ALGOLIAFACETS.global.ranges.id].length > 0
  ) {
    if ([TYPE.BRAND, TYPE.ARTIST].includes(type)) {
      gamme = filters[ALGOLIAFACETS.global.ranges.id].reduce(
        (accu, current) => {
          const matchingRangeName = state.allFacetsRangesAggregate[current]

          accu.push(`${normalizeRangeName(matchingRangeName)}-r${current}`)

          return accu
        },
        [],
      )
      gamme = gamme.length > 1 ? gamme.join('.') : gamme
    }
  }

  if (type === TYPE.SEARCH) {
    const regex = /^\/(?<root>[^/0-9]+)\/??((?<query>[^/]+)(\/)?)/gi
    const matches = regex.exec(baseUrl)
    if (matches) {
      baseUrl =
        '/' + matches.groups.root + '/' + matches.groups.query.replace(' ', '+')
    }
    if (gender) {
      ret[generateDynamicFacetName(currentInterStore.langCode, 'GENDERS')] =
        gender
    }
    if (
      filters &&
      filters[
        generateDynamicFacetName(currentInterStore.langCode, 'CATEGORIES')
      ] &&
      filters[
        generateDynamicFacetName(currentInterStore.langCode, 'CATEGORIES')
      ].length > 0
    ) {
      ret.categories =
        filters[
          generateDynamicFacetName(currentInterStore.langCode, 'CATEGORIES')
        ].join('--')
    }
  } else if ([TYPE.BRAND, TYPE.ARTIST, TYPE.CATEGORY].includes(type)) {
    /**
     * Match all possible cases for brand or Artist listing
     *   /t-shirts-manches-longues-c110/femme/adidas-b608/adidas-adidas-originals-r395/
     *   /femme/adidas-b608/adidas-adidas-originals-r395/
     *   /t-shirts-manches-longues-c110/adidas-b608/adidas-adidas-originals-r395/
     *   /t-shirts-manches-longues-c110/adidas-b608/
     *   /t-shirts-manches-longues-c110/femme
     *   /t-shirts-c9/homme/adidas-b608/adidas-originals-r395.adidas-performance-r396
     *   /femme
     *
     */
    const regex =
      /^\/((?<category>[^/]*-c[0-9]*)(\/)?)??((?<gender>[^/0-9]+)(\/)?)??(?<brand>([^/]+-(b|a)[0-9]*))*\/??(?<ranges>[^/]*-r[0-9]*\.?)*\/?$/gi
    const matches = regex.exec(baseUrl)
    if (matches) {
      baseUrl =
        (matches.groups.category ? '/' + matches.groups.category : '') +
        (gender ? '/' + gender : '') +
        (matches.groups.brand ? '/' + matches.groups.brand : '') +
        (gamme ? '/' + gamme : '')
    }
  } else {
    /* Almost the same regex, but always with first param */
    const regex =
      /^\/(?<root>[^/0-9]+)\/??((?<category>[^/]*-c[0-9]*)(\/)?)??((?<gender>[^/0-9]+)(\/)?)??((?<brand>[^/]+-(b|a)[0-9]*))*\/??(?<ranges>[^/]*-r[0-9]*\.?)*\/?$/gi
    const matches = regex.exec(baseUrl)
    if (matches) {
      baseUrl =
        '/' +
        matches.groups.root +
        (matches.groups.category ? '/' + matches.groups.category : '') +
        (gender ? '/' + gender : '') +
        (matches.groups.brand ? '/' + matches.groups.brand : '') +
        (gamme ? '/' + gamme : '')
    }
  }

  if (type === TYPE.SALES) {
    ret[SOLDES_PARAM_TRANSLATE[currentInterStore.id]] = 1
  }
  let url = '?'
  for (const key in ret) {
    if (ret[key]) {
      url += key + '=' + ret[key] + '&'
    }
  }
  return baseUrl + url.slice(0, -1)
}

function normalizeRangeName(input) {
  // Replace spaces with dashes
  let formatted = input.replace(/ /g, '-')

  // Convert to lowercase
  formatted = formatted.toLowerCase()

  // Remove consecutive dashes
  formatted = formatted.replace(/--+/g, '-')

  return formatted
}
